.mobile-services{
    background-color: white;
    .service{
        padding:14px 10px 14px 16px;
        .service-icon{
            font-size: 18px; 
         }
         .service-text{
             font-size: 14px;
             line-height: 21px;
         }
    }
    
}